<template>
    <div>

        <div class="intro-main">
        </div>
        <div class="intro-comment" :class="{displaynone:!introState}" @click="introState=!introState">
            바다를 건너온 것을 환영합니다. <br/>
            편하게 쉬다가세요.<br/>
            (클릭 후 둘러볼 수 있습니다.)
        </div>
    </div>
</template>

<script>


export default ({
    name:"intro",
    components: {
        // SignUp,
    },
    data(){
        return{
            introState:true,
        }
    },
    methods:{
    },
})
</script>
<style scoped>
.intro-main{
    overflow:hidden;
    height:100vh;
    background-image: url("../assets/beach1.jpg");
    background-size:cover;
    background-position: center;
}
.intro-comment{
    position:fixed;
    height: 100vh;
    width: 100vw;
    opacity: 0.7;
    z-index: 4;
    padding: 40vh 0;
    top:0;
    left:0;
    font-size: 20px;
    color:azure;
    background-color: black;
    text-align: center;
}
</style>